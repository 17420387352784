(function () {
    'use strict';

    angular
        .module('atheer.core')
        .directive('conversationMessageEditor', conversationMessageEditor);

    /* @ngInject */
    function conversationMessageEditor() {
        var directive = {
            scope: {
                conversationModel: '=',
                isGuest: '='
            },
            restrict: "E",
            replace: true,
            templateUrl: 'modules/conversation/conversation-message-editor.html',
            bindToController: true,
            controller: conversationMessageEditorController,
            controllerAs: 'vm',
        };
        return directive;

        /* @ngInject */
        function conversationMessageEditorController($rootScope, $scope, $mdPanel, PubNubService, Principal, $mdDialog, Message, ConversationModelService, $timeout, deviceDetector, $filter) {
            var vm = this;

            var froalaEditor = null;

            var showDownConverter = new showdown.Converter();
            showDownConverter.setOption('emoji', true);

            vm.convMessageBody = null;

            // Define popup template.
            Object.assign(FroalaEditor.POPUP_TEMPLATES, {
                'emojiPlugin.popup': '[_CUSTOM_LAYER_]'
            });

            // The custom popup is defined inside a plugin (new or existing).
            FroalaEditor.PLUGINS.emojiPlugin = function (editor) {
                // Create custom popup.
                function initPopup() {
                    // Load popup template.
                    var template = FroalaEditor.POPUP_TEMPLATES.customPopup;
                    if (typeof template == 'function') template = template.apply(editor);

                    var template = {
                        custom_layer: '<div><iframe border=0 name=iframe src="third-party/emoji/index.html" width="338" height="420" scrolling="no" noresize frameborder="0"></iframe></div>'
                    };

                    window.addEventListener('message', handleMessage, false);

                    // Create popup.
                    var $popup = editor.popups.create('emojiPlugin.popup', template);

                    return $popup;
                };

                function handleMessage(event) {
                    if (event.origin !== location.origin) return;
                    if (editor.popups.isVisible('emojiPlugin.popup') && event.data.emojiSelected) {
                        handleEmoji(event.data.emoji);
                    }
                }

                function handleEmoji(emoji) {
                    editor.html.insert(emoji.native);
                    editor.events.trigger('contentChanged', [], true);
                    hidePopup();
                };

                // Show the popup
                function showPopup() {
                    // Get the popup object defined above.
                    var $popup = editor.popups.get('emojiPlugin.popup');

                    // If popup doesn't exist then create it.
                    // To improve performance it is best to create the popup when it is first needed
                    // and not when the editor is initialized.
                    if (!$popup) $popup = initPopup();

                    // Set the editor toolbar as the popup's container.
                    editor.popups.setContainer('emojiPlugin.popup', editor.$tb);

                    // If the editor is not displayed when a toolbar button is pressed, then set BODY as the popup's container.
                    // editor.popups.setContainer('emojiPlugin.popup', $('body'));

                    // Trigger refresh for the popup.
                    // editor.popups.refresh('emojiPlugin.popup');

                    // This custom popup is opened by pressing a button from the editor's toolbar.
                    // Get the button's object in order to place the popup relative to it.
                    var $btn = editor.$tb.find('.fr-command[data-cmd="emoji"]');

                    // Compute the popup's position.
                    var left = $btn.offset().left + $btn.outerWidth() / 2 - 20;
                    var top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 5);

                    // Show the custom popup.
                    // The button's outerHeight is required in case the popup needs to be displayed above it.
                    editor.popups.show('emojiPlugin.popup', left, top, $btn.outerHeight());
                }

                // Hide the custom popup.
                function hidePopup() {
                    editor.popups.hide('emojiPlugin.popup');
                }

                // Methods visible outside the plugin.
                return {
                    showPopup: showPopup,
                    hidePopup: hidePopup
                }
            };

            // Define an icon and command for the button that opens the custom popup.
            FroalaEditor.DefineIcon('emoji', {
                NAME: 'smile-o',
                FA5NAME: 'smile',
                SVG_KEY: 'smile'
            });

            FroalaEditor.RegisterCommand('emoji', {
                title: $filter('translate')('atheer.message.emoji'),
                icon: 'emoji',
                undo: false,
                focus: false,
                popup: true,
                // Buttons which are included in the editor toolbar should have the plugin property set.
                plugin: 'emojiPlugin',
                callback: function () {
                    if (!this.popups.isVisible('emojiPlugin.popup')) {
                        this.emojiPlugin.showPopup();
                    } else {
                        if (this.$el.find('.fr-marker')) {
                            this.events.disableBlur();
                            this.selection.restore();
                        }
                        this.popups.hide('emojiPlugin.popup');
                    }
                }
            });

            // Define a text icon called textIcon.
            FroalaEditor.DefineIcon('sendIcon', {
                NAME: $filter('translate')('entity.action.send'),
                template: 'text'
            });

            FroalaEditor.RegisterCommand('sendButton', {
                title: 'Send',
                icon: 'sendIcon',
                callback: function () {
                    vm.submitTextMessage();
                },
                refresh: function ($btn) {
                    if (this.charCounter.count() > 0) {
                        $btn[0].disabled = false;
                    } else {
                        $btn[0].disabled = true;
                    };
                }
            });

            // Define a font icon called smartphoneIcon that uses the Material Design font.
            // You should also make sure that you include the Material Design Iconic font in your <head>:
            // <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.1.2/css/material-design-iconic-font.min.css" />
            FroalaEditor.DefineIconTemplate('iconTemplate', '<i class="fa fa-[NAME]" aria-hidden="true"></i>');

            FroalaEditor.DefineIcon('microphoneIcon', {
                NAME: 'microphone',
                template: 'iconTemplate'
            });

            FroalaEditor.RegisterCommand('microphoneButton', {
                title: $filter('translate')('atheer.message.audio'),
                icon: 'microphoneIcon',
                callback: function () {
                    vm.recordAudio(this.id);
                },
            });

            FroalaEditor.DefineIconTemplate('contentTemplate', '<i class="zmdi zmdi-[NAME]" aria-hidden="true"></i>');

            FroalaEditor.DefineIcon('contentIcon', {
                NAME: 'library',
                template: 'contentTemplate'
            });

            if (!vm.isGuest) {
                FroalaEditor.RegisterCommand('contentButton', {
                    title: $filter('translate')('atheer.message.content'),
                    icon: 'contentIcon',
                    callback: function () {
                        vm.shareContent(this.id);
                    },
                });
            }

            FroalaEditor.DefineIconTemplate('localContentTemplate', '<i class="fa fa-[NAME]" aria-hidden="true"></i>');

            FroalaEditor.DefineIcon('localContentIcon', {
                NAME: 'arrow-circle-o-up',
                template: 'localContentTemplate'
            });

            if (!vm.isGuest) {
                FroalaEditor.RegisterCommand('localContentButton', {
                    title: $filter('translate')('atheer.message.local'),
                    icon: 'localContentIcon',
                    callback: function () {
                        vm.shareLocalContent(this.id);
                    }
                });
            }

            vm.froalaOptions = {
                pluginsEnabled: ['align', 'charCounter', 'emojiPlugin', 'entities', 'inlineStyle', 'lineBreaker', 'lists', 'quote'],
                toolbarButtons: {
                    'moreText': {
                        'buttons': ['emoji', 'microphoneButton', 'contentButton', 'localContentButton'],
                        'buttonsVisible': 10
                    },
                    'moreMisc': {
                        'buttons': ['sendButton'],
                        'align': 'right',
                        'buttonsVisible': 3
                    }
                },
                toolbarButtonsMD: {
                    'moreText': {
                        'buttons': ['emoji', 'microphoneButton', 'contentButton', 'localContentButton'],
                        'buttonsVisible': 10
                    },
                    'moreMisc': {
                        'buttons': ['sendButton'],
                        'align': 'right',
                        'buttonsVisible': 3
                    }
                },
                toolbarButtonsSM: {
                    'moreText': {
                        'buttons': ['emoji', 'microphoneButton', 'contentButton', 'localContentButton'],
                        'buttonsVisible': 10
                    },
                    'moreMisc': {
                        'buttons': ['sendButton'],
                        'align': 'right',
                        'buttonsVisible': 3
                    }
                },
                toolbarButtonsXS: {
                    'moreText': {
                        'buttons': ['emoji', 'microphoneButton', 'contentButton', 'localContentButton'],
                        'buttonsVisible': 10
                    },
                    'moreMisc': {
                        'buttons': ['sendButton'],
                        'align': 'right',
                        'buttonsVisible': 3
                    }
                },
                enter: FroalaEditor.ENTER_P,
                toolbarInline: false,
                charCounterCount: false,
                toolbarBottom: true,
                heightMin: 10,
                zIndex: 0,
                multiLine: true,
                placeholderText: $filter('translate')('atheer.conversation.typeMessagePlaceholder'),
                emoticonsUseImage: true,
                listAdvancedTypes: false,
                events: {
                    'initialized': function () {
                        var elements = document.querySelectorAll("[data-cmd='sendButton']");
                        var sendButton = angular.element(elements[0]);
                        sendButton.removeClass('fr-btn');
                        sendButton.addClass('md-primary md-raised md-button md-primary-theme-theme md-ink-ripple');
                        sendButton[0].disabled = true;

                        if (deviceDetector.browser == 'safari') {
                            var microphoneButton = angular.element(document.querySelectorAll("[data-cmd='microphoneButton']")[0]);
                            microphoneButton.addClass('fr-disabled');
                            microphoneButton[0].style.opacity = 1;
                        }
                    },
                    'keydown': function (keypressEvent) {
                        if (keypressEvent.keyCode == 13 && !keypressEvent.shiftKey) {
                            vm.submitTextMessage();
                        }
                    },
                    'destroy': function () { }
                }
            };

            vm.shareContent = function (id) {
                $mdDialog.show({
                    templateUrl: 'modules/content/content-picker.html',
                    controller: 'ContentPickerController',
                    controllerAs: 'vm',
                    clickOutsideToClose: true,
                    locals: {
                        filter: 'embedded==false;type=out=(SERIES,EMAIL_LAYOUT,ANNOUNCEMENT,DID_YOU_KNOW);meta_type==DISCOVERABLE;status!=ARCHIVED;enabled==true',
                        allowMultiSelect: false,
                        isWeb: false,
                        isNotFilter: false
                    }
                })
                    .then(function (content) {
                        vm.submitContentMessage(content[0]);
                    });
            }

            vm.recordAudio = function (id) {
                if (deviceDetector.isMobile()) {
                    $mdDialog.show({
                        templateUrl: 'modules/conversation/conversation-audio-record-dialog.tmpl.html',
                        clickOutsideToClose: true,
                        controller: 'ConversationAudioRecordDialogController',
                        controllerAs: 'vm',
                    }).then(function (media) {
                        if (media.isSend) {
                            vm.submitAudioMessage(media.media);
                        }
                    });
                } else {
                    var position = $mdPanel.newPanelPosition()
                        .relativeTo('#microphoneButton-' + id)
                        .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW)
                        .withOffsetY(-50);

                    var config = {
                        attachTo: angular.element(document.body),
                        controller: 'ConversationAudioRecordController',
                        controllerAs: 'vm',
                        templateUrl: 'modules/conversation/conversation-audio-record-panel.tmpl.html',
                        panelClass: 'fp-conv-audio-panel',
                        position: position,
                        clickOutsideToClose: false,
                        escapeToClose: false,
                        focusOnOpen: false,
                        zIndex: 2,
                        onCloseSuccess: function (panelRef, reason) {
                            if (panelRef.send) {
                                vm.submitAudioMessage(panelRef.media);
                            }
                        }
                    };
                    $mdPanel.open(config);
                }
            };

            vm.submitAudioMessage = function (media) {
                var convMessage = {
                    conversation_id: vm.conversationModel.id,
                    subject: 'subject',
                    body: media.properties.file_name,
                    sent_on: new Date() / 1000,
                    publisher_id: Principal.getUserInfo().id,
                    type: 'AUDIO',
                    status: []
                };

                vm.submitMessage(convMessage);
            };

            vm.submitContentMessage = function (content) {
                var convMessage = {
                    conversation_id: vm.conversationModel.id,
                    subject: 'subject',
                    body: content.id, //JSON.stringify(media),//media.data.source_media.properties.file_name,
                    sent_on: new Date() / 1000,
                    publisher_id: Principal.getUserInfo().id,
                    type: 'CONTENT',
                    status: [],
                    content: content
                };
                vm.submitMessage(convMessage);
            };

            // it seems froala has a delay between user input and vm.convMessageBody, add a retry logic here
            vm.submitTextMessage = function () {
                vm.convMessageBody = froalaEditor.html.get();

                if (vm.convMessageBody != null) {

                    while (vm.convMessageBody.indexOf("<br></p>") != -1) {
                        vm.convMessageBody = vm.convMessageBody.replaceAll("<br></p>", "</p>");
                    }
                    // remove additional <p></p> at the end of the string
                    if (vm.convMessageBody.trim().length > 0 && vm.convMessageBody.endsWith("<p></p>")) {
                        vm.convMessageBody = vm.convMessageBody.split("<p></p>")[0]
                    }
                    if (vm.convMessageBody.trim().length <= 0 || vm.convMessageBody.endsWith("<p></p><p></p>")) {
                        return;
                    }

                }

                var finalConvMessageBody = showDownConverter.makeMarkdown(vm.convMessageBody)

                // remove additional \n\n at the end of the string
                if(finalConvMessageBody.endsWith("\n\n")) {
                    finalConvMessageBody = finalConvMessageBody.slice(0, -2)
                }

                var convMessage = {
                    conversation_id: vm.conversationModel.id,
                    subject: 'subject',
                    body: finalConvMessageBody,
                    sent_on: new Date() / 1000,
                    publisher_id: Principal.getUserInfo().id,
                    type: 'TEXT',
                    status: []
                };

                vm.convMessageBody = null;
                vm.submitMessage(convMessage);
                froalaEditor.html.set(null);
            };

            vm.initializeEditor = function (initControls) {
                initControls.initialize();
                froalaEditor = initControls.getEditor();
            };

            vm.submitMessage = function (convMessage) {
                if (vm.conversationModel.users && vm.conversationModel.users.length > 0) {
                    vm.conversationModel.users.forEach(function (user) {
                        if (user.user_id != vm.userId) {
                            var receiver = {
                                user_id: user.user_id,
                                read: "false",
                                read_at: null
                            };
                            convMessage.status.push(receiver);
                        }
                    });
                };

                // Removed the Text message rendering directly to avoid XSS attacks - specified in the PEN TEST - FOS-11716

                if (convMessage.type == 'TEXT') {
                    $scope.$emit('conversationMessageInternal', {
                        type: 'MESSAGE_CREATED',
                        message: convMessage
                    });
                }

                if (convMessage.type == 'CONTENT') {
                    $scope.$emit('conversationMessageInternal', {
                        type: 'CONTENT_CREATED',
                        message: convMessage
                    });
                }

                if (convMessage.type == 'AUDIO') {
                    $scope.$emit('conversationMessageInternal', {
                        type: 'AUDIO_CREATED'
                    });
                }

                Message.save(convMessage, onSaveSuccess, onSaveError);

                function onSaveSuccess(message) {
                    message.user_info = {
                        id: Principal.getUserInfo().id,
                        first_name: Principal.getUserInfo().first_name,
                        last_name: Principal.getUserInfo().last_name,
                        picture_id: Principal.getUserInfo().picture_id
                    };

                    if (message.type == 'CONTENT') {
                        message.content = convMessage.content;
                    };

                    if (message.type != 'TEXT') {
                        $scope.$emit('conversationMessageInternal', {
                            type: 'MESSAGE_CREATED',
                            message: message
                        });
                    }

                    if (message.type == 'TEXT') {
                        $scope.$emit('conversationMessageInternal', {
                            type: 'MESSAGE_CREATED_Id',
                            message: message
                        });
                    }

                    ConversationModelService.publishConvMessage(vm.conversationModel.id, 'MESSAGE_CREATED', message);
                };

                function onSaveError() {

                };
            };

            vm.shareLocalContent = function (id) {
                $mdDialog.show({
                    templateUrl: 'modules/conversation/conversation-local-content-picker.tmpl.html',
                    controller: 'ConversationLocalContentPicker',
                    controllerAs: 'vm',
                    locals: { annotationMode: false }
                })
                    .then(function (content) {
                        vm.submitContentMessage(content);
                    });
            };

            $scope.$watch('vm.conversationModel', function (newValue) {
                vm.convMessageBody = null;
            });
        }
    }
})();
